<template>
  <b-card-actions action-collapse title="Liste des Commande">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Recherche</label>
            <b-form-input
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="table-category"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          //  mode: 'records'
        }"
        theme="my-theme"
      >
    >
  <!--<template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.label === 'Start date'"
          class="text-nowrap"
        >
        <span>
           {{props.row.datedeb | moment("YYYY-MM-DD")}}
        </span>
        </span>
        <span
          v-else-if="props.column.label === 'End date'"
          class="text-nowrap"
        >
        <span>
           {{props.row.datedeb | moment("add", props.row.duration+" days","YYYY-MM-DD")}}
        </span>
        </span>
     
  </template>-->

   <template
        slot="table-row"
        slot-scope="props"
      >
       
           <!--<span
          v-if="props.column.label === 'Product'"
          class="text-nowrap"
        >
       {{props.row.product.name}}
         </span>
         <span
          v-else-if="props.column.label === 'Owner'"
          class="text-nowrap"
        >
       {{props.row.owner.fullName}}
         </span>
           <span
          v-else-if="props.column.label === 'Buyer'"
          class="text-nowrap"
        >
       {{props.row.buyer.fullName}}
         </span>
             <span
          v-else-if="props.column.label === 'Status'"
          class="text-nowrap"
        >
       {{props.row.state}}
         </span>
         <span
          v-else-if="props.column.label === 'Date'"
          class="text-nowrap"
        >
        {{props.row.date | moment("YYYY-MM-DD HH:mm")}}
       </span>-->

         <span
          v-if="props.column.label === 'Date'"
          class="text-nowrap"
        >
        {{props.row.createdAt | moment("YYYY-MM-DD HH:mm")}}
       </span>
        
         <span
          v-else-if="props.column.label === 'Actions'"
          class="text-nowrap"
        >
         <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
             <!-- <b-dropdown-item  id="toggle-btn" v-b-modal.modal-prevent-closing>
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>-->
              <b-dropdown-item @click="_deleteCommande(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
  </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Afficher 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> sur {{ props.total }} résultats </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-actions>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import commandeMixin from '@/mixins/commande.mixin';
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BModal, 
  VBModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    
    mixins: [commandeMixin],

    components: {
       VueGoodTable,
       BCard, 
       BCardHeader, BCardBody, BRow, BCol, 
       BFormInput, BButton, BDropdown, BDropdownItem, 
       BModal, VBModal, BAlert, BFormGroup, BListGroup, BListGroupItem,
       BCardActions,
         BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
    },

    data(){
     return {
            pageLength: 5,
      searchTerm: "",
     columns: [
        {
          label: 'Product',
          field: 'post.product.name',
        },
        {
          label: 'Owner',
          field: 'owner.fullName',
        },
        {
          label: 'Buyer',
          field: 'buyer.fullName',
        },
        {
          label: 'Price DT',
          field: 'post.product.price'
        },
        {
          label: 'Date',
          field: 'createdAt'
        },
        /*{
          label: 'Status',
          field: 'status'
        },*/
        {
          label: 'Actions',
          field: 'status'
        },
        
      ],
      rows: [
           /*{
                    _id : 1,
                    price: 20,
                    date : "2021-07-24T18:04:38.634Z",
                    product: 
                      {
                        id: 1,
                        name: "p1"
                      },
                    state: "no",
                    owner : {
                        _id : 1,
                        fullName : "aziz",
                        email : "aziz@gmail.com"
                    },
                    buyer : {
                        _id : 1,
                        fullName : "aziz",
                        email : "aziz@gmail.com"
                    },
                },*/
      ]
    };
  },

  mounted() {
     this.rows = this.commandes
  },

  watch : {
      commandes : function() {
        this.rows = this.commandes
      }
  },

  methods: {
    // search category
    advanceSearch(val) {
      this.searchTerm = val;
    },
    _deleteCommande(item) {
        this.$swal({
        title: "Are you sure?",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: "red",
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
         this.$store.dispatch("DeleteCommande", item).then(data => {
              if(data.data!='') {
                this.rows = []
                
                this.rows = this.commandes
              
              }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Cancel Delete",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      });
    }
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>